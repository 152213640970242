var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showSwitchType
        ? [
            _vm.state == 0
              ? _c(
                  "div",
                  [_c("a-badge", { attrs: { status: "error", text: "停用" } })],
                  1
                )
              : _vm.state == 1
              ? _c(
                  "div",
                  [
                    _c("a-badge", {
                      attrs: { status: "processing", text: "启用" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("a-badge", {
                      attrs: { status: "warning", text: "未知" },
                    }),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _vm.showSwitchType
        ? [
            _c("a-switch", {
              staticClass: "els",
              attrs: {
                "checked-children": "启用",
                "un-checked-children": "停用",
                checked: _vm.switchChecked,
              },
              on: { change: _vm.onChangeInner },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }